import React, { useEffect, useState } from "react";
import { Tabs, Button, Input, message, Dropdown, Space } from "antd";
import LoginModal from "./Authorize/LoginModal";
import UserModal from "./Authorize/UserModal";
import { clearLocalDb } from "../data/indexdb";
import {
  DeleteOutlined,
  RightOutlined,
  LeftOutlined,
  UpOutlined,
  DownOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import { elementItem } from "../../types";
import "./Tabbel.scss";
interface TabbelProps {
  items: number[];
  activeKey: number;
  onEdit: (key: number, action: "add" | "remove") => void;
  onAdd: (id: number) => void;
  sceneData: elementItem[];
  addScene: (sceneData: elementItem) => void;
  onRemove: (id: number) => void;
  onChange: (key: number) => void;
  changeLoginType: (loginType: boolean) => void;
  children: React.ReactNode;
  login: boolean;
}

const Tabbel = ({
  sceneData,
  activeKey,
  items,
  onEdit,
  onAdd,
  addScene,
  onRemove,
  onChange,
  changeLoginType,
  children,
  login,
}: TabbelProps) => {
  const [isAdd, setIsAdd] = useState(false);
  const [title, setTitle] = useState("");
  const [show, setShow] = useState(true);
  const [showNav, setShowNav] = useState(true);
  const [loginOpen, setLoginOpen] = useState(false);
  const [userOpen, setUserOpen] = useState(false);
  const [isLogin, setIsLogin] = useState(login);
  const [butnList, setButnList] = useState<MenuProps["items"]>([
    {
      key: "1",
      label: "新增",
    },
    {
      key: "2",
      label: "登录",
    },
  ]);

  useEffect(() => {
    if (login) {
      initButn(login);
      setLoginOpen(false);
    }
  }, [login]);

  const initButn = (login: boolean) => {
    setIsLogin(login);
    const userInfo = localStorage.getItem("SYNC_USERINFO")
      ? JSON.parse(localStorage.getItem("SYNC_USERINFO") as string)
      : null;
    setButnList([
      {
        key: "1",
        label: "新增",
      },
      {
        key: "2",
        label: login ? (userInfo && userInfo.nickname) || "微信用户" : "登录",
      },
    ]);
  };
  const add = () => {
    if (!title) {
      message.warning("标题不能为空");
      return;
    }
    const now = Date.now();
    const sceneDataItem: elementItem = {
      id: String(now),
      title,
      elements: [],
      created_at: now,
      updated_at: now,
      deleted_at: 0,
    };
    addScene(sceneDataItem);
    setIsAdd(false);
    setTitle("");
  };
  const onInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };
  const addItem = () => {
    setIsAdd(true);
  };
  const showTab = () => {
    setShow(!show);
  };

  const onClick: MenuProps["onClick"] = ({ key }) => {
    if (key === "1") {
      addItem();
    } else if (key === "2") {
      if (isLogin) {
        setUserOpen(true);
      } else {
        setLoginOpen(true);
      }
    }
  };

  const userInfo = localStorage.getItem("SYNC_USERINFO")
    ? JSON.parse(localStorage.getItem("SYNC_USERINFO") as string)
    : null;

  return (
    <div style={{ width: "100%" }} className="flex tab-box">
      <LoginModal
        open={loginOpen}
        onSuccess={(res) => {
          localStorage.setItem("SYNC_USERINFO", JSON.stringify(res));
          changeLoginType(true);
          setLoginOpen(false);
          initButn(true);
          message.success("登录成功");
        }}
        onCancel={() => setLoginOpen(false)}
      />
      <UserModal
        open={userOpen}
        onCancel={() => setUserOpen(false)}
        onLogout={() => {
          localStorage.setItem("SYNC_USERINFO", "");
          localStorage.setItem("SYNC_LAST_LOGIN", "0");
          localStorage.setItem("SYNC_HASH", "");
          clearLocalDb();
          message.success("退出成功");
          window.location.reload();
        }}
        data={userInfo}
      />

      <div className={["left-tab", show ? "tab-show" : ""].join(" ")}>
        <div
          className="show-butn"
          onClick={() => {
            showTab();
          }}
        >
          {!show ? (
            <RightOutlined rev={undefined} />
          ) : (
            <LeftOutlined rev={undefined} />
          )}
        </div>
        <div className="tab-main">
          <div className="box">
            <div className="tab-title flex-center flex-between flex">
              <span>文件系统</span>
              <Dropdown menu={{ items: butnList, onClick }}>
                <a onClick={(e) => e.preventDefault()}>
                  <Space>菜单</Space>
                </a>
              </Dropdown>
              {/* <Button onClick={() => { addItem() }} style={{ color: '#d1cbcb' }} type="text" icon={<PlusOutlined/>} >新增</Button> */}
            </div>
            <div className="list">
              {isAdd ? (
                <div className="item flex flex-center">
                  <Input
                    style={{ backgroundColor: "#000", color: "#d1cbcb" }}
                    value={title}
                    onInput={onInput}
                    placeholder="请输入标题"
                  />
                  <Button
                    style={{
                      width: 80,
                      backgroundColor: "#000",
                      color: "#d1cbcb",
                    }}
                    onClick={() => {
                      add();
                    }}
                  >
                    确定
                  </Button>
                </div>
              ) : (
                ""
              )}
              {sceneData.map((item) => {
                return (
                  <div
                    className="item flex flex-center flex-between"
                    key={item.created_at}
                  >
                    <div
                      className="title"
                      onClick={() => {
                        onAdd(item.created_at);
                      }}
                    >
                      {item.title}
                    </div>
                    <div
                      className="remove-butn"
                      onClick={() => {
                        onRemove(item.created_at);
                      }}
                    >
                      <DeleteOutlined rev={undefined} />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="content-tab">
        {showNav && (
          <Tabs
            hideAdd
            tabBarStyle={{ margin: 0, width: "100%", backgroundColor: "#000" }}
            tabBarGutter={0}
            type="editable-card"
            onEdit={(key, action) => onEdit(+key, action)}
            onChange={(v) => onChange(+v)}
            items={items.map((key) => {
              const item = sceneData.find((one) => one.created_at === key);

              return {
                label: item?.title,
                key: `${key}`,
              };
            })}
            activeKey={`${activeKey}`}
          />
        )}
        {!!items.length && (
          <div
            className="show-butn"
            style={{
              top: `calc(${showNav ? "40px" : "0"} + env(safe-area-inset-top))`,
            }}
            onClick={() => {
              setShowNav(!showNav);
            }}
          >
            {!showNav ? <DownOutlined rev={null} /> : <UpOutlined rev={null} />}
          </div>
        )}
        <div
          className="tab-content"
          style={{
            height: showNav ? "calc(100% - 40px)" : "100%",
          }}
        >
          {items.length > 0 ? children : ""}
        </div>
      </div>
    </div>
  );
};

export default Tabbel;
