import "./ToolIcon.scss";

import clsx from "clsx";
import { ToolButtonSize } from "./ToolButton";
import { GridIcon  } from "./icons";

type GridIconProps = {
  title?: string;
  name?: string;
  checked: boolean;
  onChange?(): void;
  isMobile?: boolean;
};

const DEFAULT_SIZE: ToolButtonSize = "medium";

export const GridButton = (props: GridIconProps) => {
  return (
    <label
      className={clsx(
        "ToolIcon ToolIcon__Grid",
        `ToolIcon_size_${DEFAULT_SIZE}`,
        {
          "is-mobile": props.isMobile,
        },
      )}
      title={`${props.title} — Ctrl+'`}
    >
      <input
        className="ToolIcon_type_checkbox"
        type="checkbox"
        name={props.name}
        onChange={props.onChange}
        checked={props.checked}
        aria-label={props.title}
        data-testid="toolbar-Grid"
      />
      <div className="ToolIcon__icon">
        {GridIcon}
      </div>
    </label>
  );
};
