import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import ExcalidrawApp from "./excalidraw-app";
import "antd/dist/antd.min.css";
import "./excalidraw-app/pwa";
import { WebSocketStateProvider } from "./hooks/useWebSocket";
window.__EXCALIDRAW_SHA__ = process.env.REACT_APP_GIT_SHA;
const rootElement = document.getElementById("root")!;
const root = createRoot(rootElement);
root.render(
  <StrictMode>
    <WebSocketStateProvider>
      <ExcalidrawApp />
    </WebSocketStateProvider>
  </StrictMode>,
);
