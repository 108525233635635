// @ts-ignore
import { ENV } from "@codernote/utils";
import { Modal } from "antd";
import { useLayoutEffect } from "react";
import conf from "../../../conf";

interface LoginModalProps {
  open: boolean;
  onCancel: () => void;
  onSuccess: (data: any) => void;
}

const LoginModal = ({ open, onCancel, onSuccess }: LoginModalProps) => {
  useLayoutEffect(() => {
    const handler = (event: any) => {
      const obj = event.data;
      switch (obj.cmd) {
        case "wxlLoginSuccess":
        case "loginSuccess":
          onSuccess(obj.data);
          break;
        default:
          break;
      }
    };

    if (open) {
      window.addEventListener("message", handler);
    } else {
      window.removeEventListener("message", handler);
    }
  }, [open, onSuccess]);

  return (
    <Modal
      className="modal-box"
      title=""
      open={open}
      footer=""
      width={1000}
      onCancel={onCancel}
      destroyOnClose
    >
      <iframe
        src={`${ENV.URL_PAGE_LOGIN_PC}?appCode=${
          conf.appCode
        }&hash=${conf.getHash()}`}
        title="登录"
        style={{ width: "100%", height: 700, border: 0 }}
      />
    </Modal>
  );
};

export default LoginModal;
