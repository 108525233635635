import Dexie from "dexie";
import { STORAGE_KEYS } from "../app_constants";
import { SceneElement } from "../../types";

export let db: any;

export function initDb() {
  db = new Dexie("Draft");
  db.version(1).stores({
    drafts: "created_at",
  });

  db.open();
}

export const getLocalDb = async () => {
  let sceneData = null;

  try {
    const legacySceneData = localStorage.getItem(
      STORAGE_KEYS.LOCAL_STORAGE_LIST,
    );

    if (legacySceneData) {
      sceneData = legacySceneData;
      localStorage.removeItem(STORAGE_KEYS.LOCAL_STORAGE_LIST);
      setLocalDb(JSON.parse(sceneData));
    } else {
      const list = await db.drafts.toArray();
      if (list.length) {
        sceneData = JSON.stringify(list);
      }
    }
  } catch (error: any) {
    // Unable to access localStorage
    console.error(error);
  }

  let sceneList: SceneElement = [];

  if (sceneData) {
    try {
      sceneList = JSON.parse(sceneData);
    } catch (error: any) {
      console.error(error);
      // Do nothing because appState is already null
    }
  }

  return sceneList;
};

export const setLocalDb = async (sceneElement: SceneElement) => {
  await clearLocalDb();
  db.drafts.bulkPut(sceneElement);
};

export const clearLocalDb = async () => {
  db.drafts.clear();
};
