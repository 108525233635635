import React from "react";

type WebSocketAppEventType = {
  // 是否登录
  isLogin: boolean;
  // 发送消息
  sendSocketData: (oper: any, data: any) => void;
  // 接收到的消息
  message: any | null;
};

export const WebSocketStateContext = React.createContext<
  WebSocketAppEventType | undefined
>(undefined);
