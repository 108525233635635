// @ts-ignore
import { ENV } from "@codernote/utils";
import { Modal } from "antd";
import { useLayoutEffect, useRef } from "react";
import conf from "../../../conf";

const UserModal = ({
  open,
  data,
  onLogout,
  onCancel,
}: {
  open: boolean;
  data: any;
  onLogout: () => void;
  onCancel: () => void;
}) => {
  const iframe = useRef<HTMLIFrameElement>(null);

  useLayoutEffect(() => {
    const handler = (event: any) => {
      const obj = event.data;
      switch (obj.cmd) {
        case "logout":
          if (obj.data === "1") {
            onLogout();
          }
          break;
        default:
          break;
      }
    };

    if (open) {
      window.addEventListener("message", handler);
    } else {
      window.removeEventListener("message", handler);
    }
  }, [open, onLogout]);

  function handleOnload() {
    if (iframe.current) {
      const iframeWin = iframe.current.contentWindow;

      if (iframeWin) {
        iframeWin.postMessage(
          {
            cmd: "getUserInfo",
            data,
          },
          "*",
        );
      }
    }
  }

  return (
    <Modal
      className="modal-box"
      title=""
      open={open}
      footer=""
      width={1000}
      onCancel={onCancel}
    >
      <iframe
        ref={iframe}
        src={`${ENV.URL_PAGE_USER_PC}?appCode=${
          conf.appCode
        }&hash=${conf.getHash()}`}
        title="用户信息"
        style={{ width: "100%", height: 600, border: 0 }}
        onLoad={handleOnload}
      />
    </Modal>
  );
};

export default UserModal;
