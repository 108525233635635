import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  useContext,
} from "react";
import conf from "../conf";
import { WebSocketStateContext } from "../context/useWebSocket";
type WebSocketAppEventType = {
  // 是否登录
  isLogin: boolean;
  // 发送消息
  sendSocketData: (oper: any, data: any) => void;
  // 接收到的消息
  message: any | null;
};

export const WebSocketStateProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [reset, setReset] = useState<boolean>(false);
  const socket = useRef<WebSocket>();
  const sendCount = useRef<number>(1);
  const timer = useRef<any>();
  const [message, setMessage] = useState<any>({});
  const [isOnline, setIsOnline] = useState<boolean>(true);
  const [isLogin, setIsLogin] = useState<boolean>(false);
  const [isSocketInit, setIsSocketInit] = useState<boolean>(false);
  const netStatusChange = () => {
    setIsOnline(!!navigator.onLine);
    console.log("---网络连接状态变化：", isOnline ? "在线" : "离线");
  };
  const sayHelloToSvr = () => {
    if (!isOnline) {
      setTimeout(sayHelloToSvr, 100);
      return;
    }
    if (socket?.current?.readyState === 1) {
      console.log("---开始握手通信---");
      socket?.current?.send(
        JSON.stringify({
          appcode: conf.appCode,
          from: "cli",
          act: "hello",
          hash: conf.getHash(),
        }),
      );
    }
  };
  // 开启事件,主动获取数据
  const sendSocketData = useCallback(
    (oper: any, data: any) => {
      clearTimeout(timer.current);
      if (socket?.current?.readyState === 1 && isOnline) {
        console.log("---发送数据，数据如下---");
        // 同步数据必须包含hash, act, oper, data四个字段
        const sync = {
          act: oper.act || "sync",
          from: "cli",
          appcode: conf.appCode,
          hash: conf.getHash(),
          oper: {
            table: conf.table,
            ...oper,
          },
          data,
        };
        const str = JSON.stringify(sync);
        console.log(sync);
        socket?.current?.send(str);
      } else {
        timer.current = setTimeout(() => {
          sendSocketData(oper, data);
        }, 1000);
      }
    },
    [sendCount],
  );

  // 关闭事件重新连接
  const socketOnClose = useCallback(() => {
    setReset(true);
  }, []);

  // 出错事件
  const socketOnError = useCallback((err: any) => {
    console.log("err: ", err);
  }, []);

  // 收发信息
  const socketOnMessage = useCallback(
    (e: any) => {
      const data = JSON.parse(e.data);
      console.log("---收到数据，数据如下---");
      console.log(data);
      const hash = conf.getHash();
      if (data.act === "hello") {
        if (data.hash && hash !== data.hash) {
          localStorage.setItem("SYNC_HASH", data.hash);
        }
        localStorage.setItem("SYNC_LAST_LOGIN", "0");
      } else if (data.act === "heart") {
      } else if (data.act === "last-login") {
        if (data.userid) {
          console.log("---登录成功---");
          if (data.hash && hash !== data.hash) {
            localStorage.setItem("SYNC_HASH", data.hash);
          }
          localStorage.setItem("SYNC_LAST_LOGIN", "1");
          setIsLogin(true);
        } else {
          localStorage.setItem("SYNC_LAST_LOGIN", "0");
        }
      } else {
        setMessage(data);
      }
    },
    [sendCount],
  );

  // 初始化连接socket
  const socketInit = useCallback(() => {
    window.addEventListener("online", netStatusChange);
    window.addEventListener("offline", netStatusChange);
    setIsSocketInit(true);
    try {
      const scoketUrl = conf.wssAddr;
      const socketObj = new WebSocket(scoketUrl);
      socketObj.addEventListener("close", socketOnClose);
      socketObj.addEventListener("error", socketOnError);
      socketObj.addEventListener("open", sayHelloToSvr);
      socketObj.addEventListener("message", socketOnMessage);
      socket.current = socketObj;
      sendCount.current = 1;
    } catch (err) {
      //
    }
  }, [socketOnClose, socketOnError, socketOnMessage, sayHelloToSvr]);

  // 初始化连接socket
  useEffect(() => {
    if (!isSocketInit) {
      socketInit();
    }
  }, [socketInit]);

  // 断线重连
  useEffect(() => {
    if (!reset) {
      return;
    }
    setTimeout(() => {
      socketInit();
      setReset(false);
    }, 30000);
  }, [reset]);

  const value: WebSocketAppEventType = {
    sendSocketData,
    message,
    isLogin,
  };
  return (
    <WebSocketStateContext.Provider value={value}>
      {children}
    </WebSocketStateContext.Provider>
  );
};

export const useWebSocketState = (): WebSocketAppEventType => {
  const state = useContext(WebSocketStateContext);

  if (!state) {
    throw new Error(
      "useWebSocketState must be used within an WebSocketStateProvider",
    );
  }

  return state;
};
