// @ts-ignore
import { ENV } from "@codernote/utils";

export const conf = {
  wssAddr: ENV.URL_SERVER_WSS,
  appCode: "draw",
  table: "main",
  getHash: () => {
    return localStorage.getItem("SYNC_HASH") || "";
  },
};
export default conf;
